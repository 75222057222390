import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import Bem from 'bemHelper';
import formatDate from 'helpers/formatDate';
import beautifyNumber from 'helpers/beautifyNumber';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';

import linksForum from 'src-sites/forum/links';

import 'styles/domik/blocks/_block-list.scss';

const classesBemBlockList = new Bem('blockList');

export default class LastTopics extends Component {
  static propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({})),
    topicList: PropTypes.arrayOf(PropTypes.shape({}))
  };

  render() {
    const { content } = this.props;

    if (!content || content.length === 0) return null;

    const uList = (
      <ul {...classesBemBlockList('ul')}>
        {map(content, (item, key) => {
          const url = get(item, 'topicUrl');

          if (!url) return null;

          const value = get(item, 'topic_title');
          const lastTime = get(item, 'topicLastPostTime', '').split(' ');

          return (
            <li
              key={key}
              {...classesBemBlockList('item')}
            >
              <Link
                {...classesBemBlockList('link')}
                builder={linksForum.sectionTopic}
                builderProps={{
                  ...item,
                  topicUrl: item.topicLastPageUrl,
                  hash: `#post-${item.topic_last_post_id}`,
                  title: value
                }}
                noRedirector
                external
              >
                <span {...classesBemBlockList('linkContent')}>
                  <span {...classesBemBlockList('text', 'link')} children={value} />
                  {lastTime &&
                    <span
                      {...classesBemBlockList('text', 'date')}
                      children={formatDate({ date: get(lastTime, '[0]'), format: 'dd.mm' })}
                    />
                  }
                  {!!item.topic_posts &&
                    <span {...classesBemBlockList('iconContainer')}>
                      <Icon {...classesBemBlockList('icon', 'comment')} type={iconTypes.commentRound} />
                      <span {...classesBemBlockList('text', 'comment')} children={beautifyNumber(item.topic_posts)} />
                    </span>
                  }
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    );

    return (
      <div {...classesBemBlockList(null, 'gray')} children={uList} />
    );
  }
}
